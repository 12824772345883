/* Inconsolata - latin-ext */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local("Inconsolata Regular"), local("Inconsolata-Regular"), url(resources/fonts/Inconsolata-Regular-LatinExt.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Inconsolata - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local("Inconsolata Regular"), local("Inconsolata-Regular"), url(resources/fonts/Inconsolata-Regular-Latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Glacial Indifference */
@font-face {
  font-family: 'Glacial Indifference';
  font-style: normal;
  src: url(./resources/fonts/GlacialIndifference-Regular.woff2) format('woff2');
}

:root {
  font-size: calc(100% + 0.5vmin);
  text-underline-offset: 0.1em;
  text-decoration-thickness: 0.06em;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;

  overscroll-behavior: none;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}